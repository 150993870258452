<template>
	<v-dialog :value="value" @input="$emit('input', $event)" max-width="500px" @keydown.esc="CloseDialog" persistent
		no-click-animation>
		<v-toolbar class="primary white--text">
			<span>{{ $t("addUserDialog_Header") }}</span><v-spacer></v-spacer>
			<v-btn icon dark @click="CloseDialog">
				<v-icon>close</v-icon>
			</v-btn></v-toolbar>
		<v-overlay :absolute="true" :value="formLoading" :z-index="4">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<v-card>
			<v-card-title>
				<div></div>
			</v-card-title>
			<v-card-text>
				<v-form data-vv-scope="formCreateUser">
					<v-radio-group v-model="details.UseCompanyAlias" row @change="ResetValidator">
						<v-radio label="Azienda" :value="true"></v-radio>
						<v-radio label="Persona Fisica" :value="false"></v-radio>
					</v-radio-group>
					<!-- Attention: VeeValidate cannot handle v-if, so add key for each field  -->
					<v-text-field v-if="!details.UseCompanyAlias" v-model="details.Name" :label="$t('formCreateUser.Name')"
						:counter="metadata['Name'].MaxLength" v-validate="metadata['Name'].ClientValidators"
						:error-messages="errors.collect('formCreateUser.Name')" data-vv-name="Name"
						:data-vv-as="$t('formCreateUser.Name')" key="formCreateUser.Name"></v-text-field>

					<v-text-field v-if="!details.UseCompanyAlias" v-model="details.Surname"
						:label="$t('formCreateUser.Surname')" :counter="metadata['Surname'].MaxLength"
						v-validate="metadata['Surname'].ClientValidators"
						:error-messages="errors.collect('formCreateUser.Surname')" data-vv-name="Surname"
						:data-vv-as="$t('formCreateUser.Surname')" key="formCreateUser.Surname"></v-text-field>

					<v-text-field v-if="details.UseCompanyAlias" v-model="details.CompanyAlias"
						:label="$t('formCreateUser.CompanyAlias')" :counter="metadata['CompanyAlias'].MaxLength"
						v-validate="metadata['CompanyAlias'].ClientValidators"
						:error-messages="errors.collect('formCreateUser.CompanyAlias')" data-vv-name="CompanyAlias"
						:data-vv-as="$t('formCreateUser.CompanyAlias')" key="formCreateUser.CompanyAlias"></v-text-field>

					<v-text-field v-model="details.Email" :label="$t('formCreateUser.Email')"
						:counter="metadata['Email'].MaxLength" v-validate="metadata['Email'].ClientValidators"
						:error-messages="errors.collect('formCreateUser.Email')" data-vv-name="Email"
						:data-vv-as="$t('formCreateUser.Email')"></v-text-field>

					<v-checkbox :disabled="photographerMode || addToRole !== 'Client'"
						:label="$t('formCreateUser.ApplyWatermarkOnPreview')"
						v-model="details.ApplyWatermarkOnPreview"></v-checkbox>
					<v-checkbox :disabled="photographerMode || addToRole !== 'Client'"
						:label="$t('formCreateUser.OpenAccount')" v-model="details.OpenAccount"></v-checkbox>
					<v-divider class="my-3"></v-divider>
					<v-btn large color="primary" @click="CreateUser">Crea l'Utente</v-btn>
				</v-form>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";

export default {
	$_veeValidate: { validator: "CreateUser" },
	components: {},
	data() {
		return {
			defaultValues: {
				OpenAccount: true,
				ApplyWatermarkOnPreview: true,
			},
			metadataLoaded: true,
			metadata: {
				Name: {
					ClientValidators: "required",
					MaxLength: null,
				},
				Surname: {
					ClientValidators: "required",
					MaxLength: null,
				},
				UserName: {
					ClientValidators: "min:8|alpha_num",
				},
				UseCompanyAlias: {
					ClientValidators: null,
					MaxLength: null,
				},
				CompanyAlias: {
					ClientValidators: "required|max:100",
					MaxLength: 100,
				},
				Email: {
					ClientValidators: "required|email",
					MaxLength: null,
				},
				Password: {
					ClientValidators: "required|min:8",
					MaxLength: null,
				},
				ApplyWatermarkOnPreview: {
					ClientValidators: null,
					MaxLength: null,
				},
				OpenAccount: {
					ClientValidators: null,
					MaxLength: null,
				},
			},

			formLoading: false,
			details: {
				UseCompanyAlias: true,
				Name: null,
				Surname: null,
				Email: null,
				CompanyAlias: null,

				OpenAccount: true,
				ApplyWatermarkOnPreview: true,
			},
		};
	},
	props: {
		value: { type: Boolean, required: true },
		addToRole: { type: String },
		dialogHeader: { type: String },
		photographerMode: { type: Boolean, default: false }
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);

			if (!val) this.ResetForm();
		},
	},
	created() {
		this.ApplicationUsersService = new CrudClient("UserManagement/Users");
		this.UserRolesService = new CrudClient("UserManagement/UserRoles");
		this.SGPClientSettingsService = new CrudClient("SGP/ClientSettings");
	},
	mounted() {
		if (this.photographerMode) {
			this.details.OpenAccount = false;
		}
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		async CreateUser() {
			const validationResult = await this.$validator.validateAll("formCreateUser");

			if (!validationResult) return;

			this.formLoading = true;
			const dto = {
				Name: this.details.Name,
				Surname: this.details.Surname,
				Email: this.details.Email,
				UseCompanyAlias: this.details.UseCompanyAlias,
				CompanyAlias: this.details.CompanyAlias,
			};

			try {
				const userId = await this.ApplicationUsersService.Post(
					null,
					dto,
					"CreateUser",
					true
				);

				if (this.addToRole) {
					await this.UserRolesService.PostCustom(
						null,
						null,
						`${userId}/RoleName/${this.addToRole}`
					);

					if (this.addToRole === "Client") {
						this.SGPClientSettingsService.Post(userId, {
							ApplyWatermarkOnPreview: this.details.ApplyWatermarkOnPreview,
							OpenAccount: this.details.OpenAccount,
						});
					}
				}

				this.snackSuccess({ Text: this.$t("createuser_Success") });
				this.$emit("updated", userId);
			} catch (error) {
				this.snackError({ Text: this.$t("createuser_Error") });
			} finally {
				this.formLoading = false;
				this.CloseDialog();
			}
		},

		ResetValidator() {
			this.$log.debug("reset validator");
			this.$validator.attach();
		},

		ResetForm() {
			this.$log.debug("reset form");
			this.details.UseCompanyAlias = true;
			this.details.Name = null;
			this.details.Surname = null;
			this.details.Email = null;
			this.details.CompanyAlias = null;

			this.details.OpenAccount = true;
			this.details.ApplyWatermarkOnPreview = true;

			this.$validator.reset();
		},

		CloseDialog() {
			this.$emit("input", false);
		},
	},
};
</script>

<i18n>
{
    "it": {
		"addUserDialog_Header": "Crea Nuovo Utente",
		"createuser_Success": "Utente creato!",
		"createuser_Error" : "Utente non creato!",
		"Name": "Nome",
		"Surname": "Cognome",
		"FullName": "Nome Cognome",
		"formCreateUser": {
			"Name": "Nome",
			"Surname": "Cognome",
			"UserName": "Nome Utente",
			"Email": "E-mail",
			"Password": "Password",
			"UseCompanyAlias": "Usa nome azienda",
			"CompanyAlias": "Nome azienda",
			"ApplyWatermarkOnPreview": "Watermark su anteprima",
			"OpenAccount": "Open Account (non selezionare per PayPal)"
		}
    }
}
</i18n>
