<template>
	<div>
		<div class="mb-3">
			<v-row
				dense
				v-for="(user, index) in items"
				:key="user.UserId"
				:class="index % 2 ? 'blue lighten-4' : 'grey lighten-4'"
			>
				<v-col cols="4"> {{ user.AspNetUser.UserProfile.DisplayName }}</v-col>
				<v-col cols="6">
					<v-chip
						v-for="item in userSkills[user.UserId]"
						:key="item.SkillId"
						:color="item.Skill.IsActive ? 'blue' : 'blue lighten-2'"
						dark
						label
						class="mr-3"
						>{{ item.Skill.Name }}</v-chip
					>
				</v-col>
				<v-col cols="2" class="text-right">
					<v-icon
						color="primary"
						@click="$router.push('/Users/EditUser/' + user.UserId)"
						>fas fa-fw fa-user-edit</v-icon
					>
				</v-col>
			</v-row>
		</div>
		<v-btn color="info" @click="showCreateSubAccountrDialog = true"
			>Nuovo sub account</v-btn
		>
		<CreateSubAccountDialog
			v-model="showCreateSubAccountrDialog"
			@updated="LoadUserList"
			:OwnerAccountUserId="UserId"
		>
		</CreateSubAccountDialog>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import CreateSubAccountDialog from "@/components/Account/CreateSubAccountDialog";
import { mapActions } from "vuex";

export default {
	components: {
		CreateSubAccountDialog,
	},
	data() {
		return {
			LoadingData: false,
			items: [],
			userSkills: {},
			showCreateSubAccountrDialog: false,
		};
	},
	props: {
		UserId: { type: String },
	},
	watch: {
		UserId: async function () {
			if (this.UserId) this.LoadUserList();
		},
	},
	created() {
		this.ApplicationUsersService = new CrudClient("UserManagement/Users");
		this.UserSkillsService = new CrudClient("SGP/UserTags");
	},

	async mounted() {
		await this.LoadUserList();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadUserList() {
			const filter = "OwnerApplicationUser.OwnerApplicationUserId:" + this.UserId;

			try {
				this.LoadingData = true;
				const res = await this.ApplicationUsersService.GetPaged({
					limit: 0,
					skip: 0,
					fields: `*,
						AspNetUser.UserProfile.DisplayName`,
					filter: filter,
				});
				this.items = res.Data;
				for (const userAccount of this.items) {
					await this.LoadUserSkills(userAccount.UserId);
				}
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		async LoadUserSkills(userId) {
			this.$log.debug("Loading user tags");

			this.LoadingData = true;
			try {
				const res = await this.UserSkillsService.GetPaged({
					limit: 0,
					skip: 0,
					fields: "*, Skill.*",
					orderBy: "Skill.Name",
					urlPostFix: userId,
				});

				this.$set(this.userSkills, userId, res.Data);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
			}
		},
	},
};
</script>
