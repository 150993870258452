<template>
	<v-dialog
		:value="value"
		@input="$emit('input', $event)"
		max-width="500px"
		@keydown.esc="CloseDialog"
		persistent
		no-click-animation
	>
		<SimpleFormCard
			ref="formCreateSubAccount"
			:formContent="formCreateSubAccount"
			:metadata="metadata"
			:metadataLoaded="metadataLoaded"
			:initialValues="initialValues"
			@submitForm="CreateUser"
			:showCancelButton="false"
			:locali18n="$i18n"
		>
			<template slot="card-title">
				<div
					class="
						primary
						white--text
						px-5
						py-3
						d-flex
						align-center
						justify-space-between
					"
				>
					<span class="headline" style="word-break: normal">{{
						$t("addUserDialog_Header")
					}}</span>
					<v-btn icon dark @click="CloseDialog">
						<v-icon>close</v-icon>
					</v-btn>
				</div>
			</template>
		</SimpleFormCard>
	</v-dialog>
</template>
<script>
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import CrudFormData from "@/utilities/CrudFormData.js";

export default {
	$_veeValidate: { validator: "CreateUser" },
	components: { SimpleFormCard },
	data() {
		return {
			metadataLoaded: true,
			metadata: {
				Name: {
					ClientValidators: "required",
					MaxLength: null,
				},
				OwnerAccountId: {
					ClientValidators: "required",
					MaxLength: null,
				},
				Surname: {
					ClientValidators: "required",
					MaxLength: null,
				},
				UserName: {
					ClientValidators: "required|min:6",
					MaxLength: null,
				},
				Email: {
					ClientValidators: "required|email",
					MaxLength: null,
				},
				Password: {
					ClientValidators: "required|min:8",
					MaxLength: null,
				},
			},
			formCreateSubAccount: new CrudFormData("formCreateSubAccount", [
				{
					type: "v-select",
					FieldName: "OwnerAccountId",
					items: [],
					"item-text": "AspNetUser.UserProfile.DisplayName",
					"item-value": "UserId",
				},
				"Name",
				"Surname",

				"Email",
			]),
			initialValues: null,
			LoadingUsers: false,
		};
	},
	props: {
		value: { type: Boolean, required: true },
		OwnerAccountUserId: { type: String },
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);

			if (val) {
				await this.LoadUserList();
			}

			if (!val) this.ClearNewUserDialogForm();
		},
	},
	created() {
		this.ApplicationUsersService = new CrudClient("UserManagement/Users");
		this.UserRolesService = new CrudClient("UserManagement/UserRoles");
	},
	async mounted() {
		this.$log.info("Create Sub-Account Dialog mounted");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		async CreateUser(dto) {
			this.formCreateSubAccount.FormLoading = true;

			try {
				const userId = await this.ApplicationUsersService.Post(
					null,
					dto,
					"CreateSubAccountUser",
					true
				);

				const roleName = "ClientSubAccount";
				await this.UserRolesService.PostCustom(
					null,
					null,
					`${userId}/RoleName/${roleName}`
				);

				this.snackSuccess({ Text: this.$t("createuser_Success") });
			} catch (error) {
				this.snackError({ Text: this.$t("createuser_Error") });
			} finally {
				this.formCreateSubAccount.FormLoading = false;
				this.CloseDialog();
				this.$emit("updated");
			}
		},

		ClearNewUserDialogForm() {
			if (this.$refs.formCreateSubAccount) {
				this.$log.debug("formCreateSubAccount ResetForm called ");
				this.$refs.formCreateSubAccount.ResetForm();
			}
		},

		CloseDialog() {
			this.$emit("input", false);
		},

		async LoadUserList() {
			try {
				this.LoadingUsers = true;
				const res = await this.ApplicationUsersService.GetPaged({
					limit: 0,
					skip: 0,
					fields: "UserId, AspNetUser.UserProfile.DisplayName",
					filter: "ApplicationUserRoles.AspNetRole.Name:Client",
					orderBy: "AspNetUser.UserProfile.DisplayName",
				});
				const clientAccounts = res.Data;

				const ownerAccountIdField = this.formCreateSubAccount.Fields.find(
					(f) => f.FieldName === "OwnerAccountId"
				);

				ownerAccountIdField.items = [...clientAccounts].sort();
				if (this.OwnerAccountUserId) {
					ownerAccountIdField.disabled = true;
					// ownerAccountIdField.value = this.OwnerAccountUserId;
					this.initialValues = {
						OwnerAccountId: this.OwnerAccountUserId,
					};
				}
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingUsers = false;
			}
		},
	},
};
</script>

<i18n>
{
    "it": {
		"addUserDialog_Header": "Crea Account Collegato",
		"createuser_Success": "Utente creato!",
		"createuser_Error" : "Utente non creato!",
		"Name": "Nome",
		"Surname": "Cognome",
		"FullName": "Nome Cognome",
		"formCreateSubAccount": {
			"OwnerAccountId": "Account Principale",
			"Name": "Nome",
			"Surname": "Cognome",
			"UserName": "Nome Utente",
			"Email": "E-mail",
			"Password": "Password"
		}
    }
}
</i18n>
