<template>
	<div>
		<v-row dense>
			<v-col cols="12" sm="6" lg="3">
				<v-card class="mb-4">
					<v-card-title> Dati Personali </v-card-title>
					<v-card-text>
						<div class="text--primary">
							<span v-if="userProfile.UseCompanyAlias">
								<span class="font-weight-bold pr-1">{{ $t("CompanyAlias") }}:</span
								>{{ userProfile.CompanyAlias | emptyField }}</span
							>
							<template v-else>
								<span class="font-weight-bold pr-1">{{ $t("label_Name") }}:</span
								>{{ userProfile.Name }}
								<br />
								<span class="font-weight-bold pr-1">{{ $t("label_Surname") }}:</span
								>{{ userProfile.Surname }}
							</template>

							<br />
						</div>
						<div class="mb-3 text--primary" v-if="userProfile.AspnetUser">
							<span
								v-if="userProfile.AspnetUser.UserName !== userProfile.AspnetUser.Email"
							>
								<span class="font-weight-bold pr-1">{{ $t("label_Username") }}:</span
								>{{ userProfile.AspnetUser.UserName }} <br
							/></span>
							<span class="font-weight-bold pr-1">{{ $t("label_Email") }}:</span
							>{{ userProfile.AspnetUser.Email }}
						</div>
					</v-card-text>
					<v-card-actions>
						<v-btn @click="ShowResetPasswordDialog" color="primary">{{
							$t("resetPassword")
						}}</v-btn>
						<v-spacer></v-spacer>
						<v-btn icon @click="ShowEditUserProfileDialog">
							<v-icon>fas fa-edit</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
				<v-dialog
					v-model="showResetPasswordDialog"
					max-width="500px"
					@keydown.esc="showResetPasswordDialog = false"
				>
					<!-- :initialValues="userProfile" -->
					<SimpleFormCard
						ref="formResetPassword"
						:formContent="formResetPassword"
						:metadata="metadataReset"
						:metadataLoaded="true"
						@submitForm="ResetPassword"
						:showCancelButton="false"
						:locali18n="$i18n"
					>
						<template slot="card-title">
							<div
								class="
									primary
									white--text
									px-5
									py-3
									d-flex
									align-center
									justify-space-between
								"
							>
								<span class="headline" style="word-break: normal">{{
									$t("resetPassword_Header")
								}}</span>
								<v-btn icon dark @click="showResetPasswordDialog = false">
									<v-icon>close</v-icon>
								</v-btn>
							</div>
						</template>
					</SimpleFormCard>
				</v-dialog>

				<v-dialog
					v-model="showEditUserProfileDialog"
					max-width="500px"
					@keydown.esc="showEditUserProfileDialog = false"
				>
					<!-- :initialValues="userProfile" -->
					<SimpleFormCard
						ref="formUserProfile"
						:formContent="formUserProfile"
						:metadata="metadata"
						:metadataLoaded="metadataLoaded"
						@submitForm="SaveUserProfile"
						:showCancelButton="false"
						:locali18n="$i18n"
					>
						<template slot="card-title">
							<div
								class="
									primary
									white--text
									px-5
									py-3
									d-flex
									align-center
									justify-space-between
								"
							>
								<span class="headline" style="word-break: normal">{{
									$t("editProfile_Header")
								}}</span>
								<v-btn icon dark @click="showEditUserProfileDialog = false">
									<v-icon>close</v-icon>
								</v-btn>
							</div>
						</template>
					</SimpleFormCard>
				</v-dialog>
			</v-col>

			<v-col
				cols="12"
				sm="6"
				lg="3"
				v-if="Roles.find((r) => r.RoleName === 'Client')"
			>
				<SGPClientSettings :UserId="UserId"></SGPClientSettings>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import CrudFormData from "@/utilities/CrudFormData.js";
import metadata from "@/mixins/metadata";

import SGPClientSettings from "./SgpClientSettings.vue";

export default {
	$_veeValidate: { validator: "EditUserProfile" },
	components: { SimpleFormCard, SGPClientSettings },
	mixins: [metadata],
	props: {
		UserId: {
			type: String,
		},
		Roles: {
			type: Array,
		},
	},
	data() {
		return {
			userProfile: {},
			showEditUserProfileDialog: false,
			formUserProfile: new CrudFormData("formUserProfile", [
				{
					FieldName: "UseCompanyAlias",
					type: "v-checkbox",
				},
				"Name",
				"Surname",
				"CompanyAlias",

				// "MobilePhoneNo",
				// {
				// 	FieldName: "IsMobilePhoneVerified",
				// 	type: "v-checkbox",
				// },
				// "PostalCode",
				// {
				// 	FieldName: "IsActive",
				// 	type: "v-checkbox",
				// },
			]),
			showResetPasswordDialog: false,
			formResetPassword: new CrudFormData("formResetPassword", ["Password"]),
			metadataReset: {
				Password: {
					AllowSelect: true,
					AllowPatch: false,
					AllowFilter: true,
					AllowSorting: true,
					MaxLength: 20,
					ClientValidators: "min:8|max:20|required",
					DataType: 1,
					NestedParameters: null,
				},
			},
		};
	},
	computed: { ...mapGetters(["isUserInRole"]) },

	watch: {
		showEditUserProfileDialog: function (val) {
			!val && this.ClearForm();
		},
		showResetPasswordDialog: function (val) {
			!val && this.ClearResetForm();
		},
	},
	created() {
		this.UserProfilesService = new CrudClient("UserManagement/UserProfile");
	},
	async mounted() {
		await this.LoadMetadata(this.UserProfilesService, false);

		this.userProfile = await this.UserProfilesService.GetCustom(
			this.UserId,
			false
		);
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		async ShowEditUserProfileDialog() {
			this.showEditUserProfileDialog = true;
			await Vue.nextTick();
			this.$refs.formUserProfile.SetValues(this.userProfile);
		},
		async SaveUserProfile(dto) {
			try {
				this.formUserProfile.FormLoading = true;

				await this.UserProfilesService.Patch(this.UserId, dto, false, false);

				this.snackSuccess({ Text: this.$t("common.success.updateData") });
				this.ClearForm();
				this.showEditUserProfileDialog = false;

				// reload user data
				this.userProfile = await this.UserProfilesService.GetCustom(
					this.UserId,
					false
				);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("brand.error.cannotUpdateData") });
			} finally {
				this.formUserProfile.FormLoading = false;
			}
		},
		// TODO: form resetlerken user data'yı tekrar initilaValues olarak getirmeli
		ClearForm() {
			this.$log.debug("Clear form called");
			this.$refs.formUserProfile.ResetForm();
		},

		ShowResetPasswordDialog() {
			this.showResetPasswordDialog = true;
		},

		async ResetPassword(dto) {
			try {
				this.formResetPassword.FormLoading = true;
				await this.UserProfilesService.PostCustom(
					`${this.UserId}/ResetPassword`,
					dto,
					null,
					false,
					false
				);
				this.showResetPasswordDialog = false;
				this.snackSuccess({ Text: this.$t("resetSuccess") });

				this.ClearResetForm();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("resetError") });
			} finally {
				this.formResetPassword.FormLoading = false;
			}
		},
		ClearResetForm() {
			this.$refs.formResetPassword.ResetForm();
		},
	},
};
</script>
<i18n>
{
    "it": {
		"label_NameSurname": "Nome e Cognome",
		"label_Surname": "Cognome",
		"label_Name": "Nome",
		"label_Password": "Password",
		"label_Mobile": "Cellulare",
		"label_Email": "E-mail",
		"label_Username": "Nome utente",
		"yourSurname": "Il tuo cognome",
		"yourName": "Il tuo nome",
        "MobilePhone": "Il tuo cellulare",
		"resetPassword": "Reimposta la Password",
        "verifiedNumber": "Numero verificato",
		"VerifyNow": "Verifica Ora",
		"VerifyNow_Tooltip": "Verifica il numero di telefono",
		"CompanyAlias": "Nome Profilo",
		"UseCompanyAlias": "Usa Nome Profilo personalizzato",
		"CompanyAlias": "Nome Profilo",
		"editProfile_Header": "Modifica Profilo",
		"resetSuccess": "Password reimpostata con successo",
		"resetError": "Impossibile reimpostare la password ",
		"resetPassword_Header": "Reimposta la password",

		"formUserProfile":{
			"Name": "Nome",
			"Surname": "Cognome",
			"MobilePhoneNo": "Cellulare",
			"IsMobilePhoneVerified": "Cellulare verificato?",
			"CompanyAlias": "Nome Profilo",
			"UseCompanyAlias": "Usa Nome Profilo personalizzato"
		},
		"formResetPassword":{
			"Password": "Password"
		}
	}
}</i18n>
