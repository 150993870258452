<template>
	<div>
		<confirm ref="confirm"></confirm>
		<v-progress-linear :indeterminate="true" v-show="LoadingShippingAddresses"></v-progress-linear>
		<v-alert type="warning" v-if="ShippingAddresses.length === 0">
			{{ $t("alertMissingShippingAddress") }}
		</v-alert>

		<v-row dense>
			<v-col cols="12" sm="6" lg="3" v-for="profile in ShippingAddresses" :key="profile.ShippingAddressId">
				<v-card class="mb-4">
					<v-card-text>
						<div class="mb-3 text--primary">
	<!-- // Country.DescrizioneNazione
			// City,
			// PostalCode,
			// DeliveryAddress,
			// AdditionalInfo,
			// AddressTitle -->
							<span class="font-weight-bold pr-1">{{ $t("formShippingAddress.AddressTitle") }}:</span>{{ profile.AddressTitle
							}}
							<br />
							<span class="font-weight-bold pr-1">{{ $t("formShippingAddress.DeliveryAddress") }}:</span>
							<br />
							{{ profile.DeliveryAddress }}
							<br />
							{{ profile.PostalCode }}
							{{ profile.City }}
							{{ profile.Country.DescrizioneNazione }}
							<br />
							<span class="font-weight-bold pr-1">{{ $t("formShippingAddress.AdditionalInfo") }}:</span>{{
								profile.AdditionalInfo }}
						</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn icon @click="DeleteShippingAddress(profile.ShippingAddressId)">
							<v-icon>fas fa-trash</v-icon>
						</v-btn>
						<v-btn icon @click="ShowEditShippingAddressDialog(profile.ShippingAddressId)">
							<v-icon>fas fa-edit</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>

		<v-btn block class="primary" @click="ShowNewShippingAddressDialog">{{
			$t("common.add")
		}}</v-btn>

		<AddEditShippingAddress v-model="showAddShippingAddress" :mode="dialogData.Mode" :UserId="UserId"
			:ShippingAddressId="dialogData.ShippingAddressId" @updated="OnShippingAddressUpdated"></AddEditShippingAddress>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";

import AddEditShippingAddress from "./AddEditShippingAddress";
import Confirm from "@/components/Shared/Common/Confirm";

export default {
	components: { AddEditShippingAddress, Confirm },

	props: {
		UserId: {
			type: String,
		},
	},
	data() {
		return {
			showAddShippingAddress: false,
			ShippingAddresses: [],
			LoadingShippingAddresses: false,
			dialogData: {
				Mode: 1,
				ShippingAddressId: null,
			},
		};
	},
	watch: {
		showAddShippingAddress: function (val) {
			if (!val) this.dialogData.ShippingAddressId = null;
		},
	},
	created() {
		// add services to this
		if (this.UserId) {
			this.ShippingAddressesService = new CrudClient("Shop/ShippingAddresses"
			);
		} else {
			this.ShippingAddressesService = new CrudClient(
				"Shop/ShopClients/ShippingAddresses"
			);
		}
	},
	async mounted() {
		await this.LoadUserShippingAddresses();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		// ShippingAddressId,
		// UserId,
		// CountryId,
		// Country.DescrizioneNazione
		// City,
		// PostalCode,
		// DeliveryAddress,
		// AdditionalInfo,
		// AddressTitle
		async LoadUserShippingAddresses() {
			try {
				const userId = this.UserId;
				this.LoadingShippingAddresses = true;
				const res = await this.ShippingAddressesService.GetPaged({
					limit: 0,
					fields: "*, Country.DescrizioneNazione",
					filter: "IsActive:true",
					urlPostFix: this.UserId ? `User/${userId}` : "",
				});

				this.ShippingAddresses = res.Data;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingShippingAddresses = false;
			}
		},

		async OnShippingAddressUpdated() {
			this.showAddShippingAddress = false;
			await this.LoadUserShippingAddresses();
		},

		ShowNewShippingAddressDialog() {
			this.showAddShippingAddress = true;
			this.dialogData.Mode = 1;
			this.dialogData.ShippingAddressId = null;
		},
		async ShowEditShippingAddressDialog(id) {
			this.showAddShippingAddress = true;
			this.dialogData.Mode = 2;
			this.dialogData.ShippingAddressId = id;
		},

		async DeleteShippingAddress(ShippingAddressId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("confirmDeleteShippigAddress")
				))
			) {
				return;
			}

			try {
				await this.ShippingAddressesService.Delete(ShippingAddressId);
				this.snackSuccess({ Text: this.$t("common.success.deleteAddress") });
				await this.LoadUserShippingAddresses();
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotDeleteData") });
				this.$captureError(error);
			} finally {
				this.LoadingShippingAddresses = false;
			}
		}
	},
};
</script>
<i18n>
{
	"it": {
		"formShippingAddress":{
			"DeliveryAddress": "Indirizzo di consegna",
			"AdditionalInfo": "Ulteriori informazioni",
			"AdditionalInfo_hint": "Codice, edificio, azienda",
			"AddressTitle": "Nome e cognome",
			"City": "Città",
			"Address": "Indirizzo",
			"PostalCode": "CAP",
			"CountryId": "Paese"
		},
		"alertMissingShippingAddress": "Indirizzo di consegna mancante",
		"confirmDeleteShippigAddress": "Sei sicuro di voler cancellare l'indirizzo?"
	},
	"en": {
		"formShippingAddress":{
			"DeliveryAddress": "Delivery address",
			"AdditionalInfo": "Additional address information",
			"AdditionalInfo_hint": "Code, building, company",
			"AddressTitle": "Full name",
			"City": "City",
			"Address": "Registred address",
			"PostalCode": "Postal Code",
			"CountryId": "Country"
		},
		"alertMissingShippingAddress": "No delivery addresses found",
		"confirmDeleteShippigAddress": "Are you sure you want to delete the shipping address?"
	}
}
</i18n>
