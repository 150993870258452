<template>
	<div>
		<v-card class="ma-4 pa-4">
			<v-card-title>{{ $t("addSkill") }}</v-card-title>
			<v-card-text>
				<v-combobox
					item-text="Name"
					item-value="SkillId"
					v-model="selectedSkill"
					:items="AvailableUserSkills"
					:label="$t('selectSkill')"
				></v-combobox>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="AddUserSkill" :disabled="!selectedSkill">{{
					$t("common.add")
				}}</v-btn>
			</v-card-actions>
		</v-card>

		<v-card class="ma-4 pa-4">
			<v-card-title>{{ $t("userSkill") }}</v-card-title>
			<v-card-text>
				<template v-if="this.LoadingUserSkills">
					<v-chip v-for="n in 4" :key="n" class="shine mr-3 mt-2" label>
						<v-icon left class="grey--text text--lighten-3">label</v-icon>
						<span class="px-2">
							<v-icon left class="grey--text text--lighten-3"
								>fas fa-ellipsis-h</v-icon
							>
						</span>
					</v-chip>
				</template>

				<template v-else>
					<v-chip
						class="mr-3 mt-2"
						v-for="item in userSkills"
						:key="item.SkillId"
						:color="item.Skill.IsActive ? 'blue' : 'blue lighten-2'"
						dark
						label
						@click:close="RemoveUserSkill(item.UserSkillId)"
						close
						>{{ item.Skill.Name }}</v-chip
					>
				</template>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

export default {
	data() {
		return {
			allSkills: [],
			userSkills: [],
			selectedSkill: null,
			LoadingData: false,
			LoadingUserSkills: false,
			AvailableUserSkills: [],
		};
	},
	props: {
		UserId: { type: String },
		UserView: { type: Boolean, default: false },
	},
	watch: {
		UserId: async function () {
			this.LoadUserSkills();
		},
	},
	created() {
		this.SkillsService = new CrudClient("SGP/Tags");
		this.UserSkillsService = new CrudClient("SGP/UserTags");
	},

	async mounted() {
		await this.LoadSkillsList();
		await this.LoadUserSkills();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadSkillsList() {
			this.$log.debug("Loading tags");
			this.LoadingData = true;
			try {
				const res = await this.SkillsService.GetPaged({
					limit: 0,
					skip: 0,
					fields: "SkillId, Name, IsActive",
					orderBy: "Name",
					filter: "IsActive:true",
				});
				this.allSkills = res.Data;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
			}
		},

		async LoadUserSkills() {
			this.$log.debug("Loading user tags");
			if (!this.UserId) return;

			this.LoadingUserSkills = true;
			try {
				const res = await this.UserSkillsService.GetPaged({
					limit: 0,
					skip: 0,
					fields: "*, Skill.*",
					orderBy: "Skill.Name",
					urlPostFix: this.UserView ? null : this.UserId,
				});
				this.userSkills = res.Data;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingUserSkills = false;
			}

			this.AvailableUserSkills = this.allSkills.filter(
				(skill) =>
					!this.userSkills.map((us) => us.Skill.SkillId).includes(skill.SkillId)
			);
		},

		async AddUserSkill() {
			this.$log.debug("combobox");
			this.$log.debug(this.selectedSkill);

			this.LoadingUserSkills = true;
			try {
				let dto;

				if (typeof this.selectedSkill === "string") {
					dto = { SkillName: this.selectedSkill };
				} else {
					dto = { SkillId: this.selectedSkill.SkillId };
				}

				await this.UserSkillsService.Post(
					null,
					dto,
					this.UserView ? null : this.UserId,
					false
				);

				this.selectedSkill = null;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingUserSkills = false;
			}

			await this.LoadSkillsList();
			await this.LoadUserSkills();
		},

		async RemoveUserSkill(userSkillId) {
			this.LoadingUserSkills = true;
			try {
				await this.UserSkillsService.Delete(
					userSkillId,
					this.UserView ? null : this.UserId
				);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingUserSkills = false;
			}

			await this.LoadSkillsList();
			await this.LoadUserSkills();
		},
	},
};
</script>
<i18n>
{
    "it":{
        "addSkill": "Aggiungi Tag",
        "userSkill": "Tag dell'utente",
        "selectSkill": "Scegli tag"
    }
}
</i18n>
