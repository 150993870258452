<template>
	<div>
		<v-card class="mb-4">
			<v-card-title> Impostazioni</v-card-title>
			<v-card-text>
				<div class="mb-3 text--primary">
					<span class="font-weight-bold pr-1"
						>{{ $t("label_ApplyWatermarkOnPreview") }}:</span
					>
					<v-icon>
						{{
							userSettings.ApplyWatermarkOnPreview
								? "fas fa-check-square"
								: "far fa-square"
						}}
					</v-icon>
					<br />

					<span class="font-weight-bold pr-1">{{ $t("label_OpenAccount") }}:</span>
					<v-icon>
						{{ userSettings.OpenAccount ? "fas fa-check-square" : "far fa-square" }}
					</v-icon>
					<br />

					<span class="font-weight-bold pr-1"
						>{{ $t("formSgpClientSettings.DefaultLicenseId") }}:</span
					>

					{{
						userSettings.PhotographyLicense
							? userSettings.PhotographyLicense.Name
							: "-"
					}}
				</div>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn icon @click="ShowNewBillingProfileDialog">
					<v-icon>fas fa-edit</v-icon>
				</v-btn>
			</v-card-actions>
		</v-card>
		<v-dialog
			v-model="showSgpClientSettingDialog"
			max-width="500px"
			@keydown.esc="showSgpClientSettingDialog = false"
		>
			<SimpleFormCard
				ref="formSgpClientSettings"
				:formContent="formSgpClientSettings"
				:initialValues="userSettings"
				:metadata="metadata"
				:metadataLoaded="metadataLoaded"
				@submitForm="SaveSgpClientSettings"
				:showCancelButton="false"
				:locali18n="$i18n"
			>
				<template slot="card-title">
					<div
						class="
							primary
							white--text
							px-5
							py-3
							d-flex
							align-center
							justify-space-between
						"
					>
						<span class="headline" style="word-break: normal">{{
							$t("editSgpClientSettings")
						}}</span>
						<v-btn icon dark @click="showSgpClientSettingDialog = false">
							<v-icon>close</v-icon>
						</v-btn>
					</div>
				</template>
			</SimpleFormCard>
		</v-dialog>
	</div>
</template>
<script>
// import Vue from "vue";
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import CrudFormData from "@/utilities/CrudFormData.js";
import metadata from "@/mixins/metadata";

export default {
	$_veeValidate: { validator: "EditUserProfile" },
	components: { SimpleFormCard },
	mixins: [metadata],
	props: {
		UserId: {
			type: String,
		},
	},
	data() {
		return {
			userSettings: {},
			showSgpClientSettingDialog: false,
			formSgpClientSettings: new CrudFormData("formSgpClientSettings", [
				{
					FieldName: "ApplyWatermarkOnPreview",
					type: "v-checkbox",
				},
				{
					FieldName: "OpenAccount",
					type: "v-checkbox",
				},
				{
					FieldName: "DefaultLicenseId",
					type: "v-select",
					items: [],
					"item-text": "Name",
					"item-value": "LicenseId",
				},
			]),
		};
	},
	watch: {
		showSgpClientSettingDialog: function (val) {
			!val && this.ClearForm();
		},
	},
	created() {
		this.SGPClientSettingsService = new CrudClient("SGP/ClientSettings");
		this.PhotographyLicensesService = new CrudClient("SGP/PhotographyLicenses");
	},
	async mounted() {
		await this.LoadMetadata(this.SGPClientSettingsService);
		await this.LoadSgpClientSettings();
		await this.LoadLicenses();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadSgpClientSettings() {
			this.userSettings = await this.SGPClientSettingsService.GetSelectedFields(
				this.UserId,
				"*, PhotographyLicense.Name"
			);
		},

		async SaveSgpClientSettings(dto) {
			try {
				this.formSgpClientSettings.FormLoading = true;

				await this.SGPClientSettingsService.Patch(this.UserId, dto, false, true);

				this.snackSuccess({ Text: this.$t("common.success.updateData") });

				this.ClearForm();
				this.showSgpClientSettingDialog = false;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("brand.error.cannotSaveData") });
			} finally {
				this.formSgpClientSettings.FormLoading = false;

				await this.LoadSgpClientSettings();
			}
		},
		ShowNewBillingProfileDialog() {
			this.showSgpClientSettingDialog = true;
		},
		ClearForm() {
			this.$refs.formSgpClientSettings.ResetForm();
		},

		async LoadLicenses() {
			try {
				this.LoadingData = true;

				const res = await this.PhotographyLicensesService.GetPaged({
					skip: 0,
					limit: 0,
					fields: "LicenseId, Name",
					filter: "IsActive:true",
					orderBy: "SortOrder",
				});

				const licenses = res.Data;

				this.formSgpClientSettings.Fields.find(
					(f) => f.FieldName === "DefaultLicenseId"
				).items = [{ LicenseId: null, Name: "-" }, ...licenses];
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
			}
		},
	},
};
</script>
<i18n>
{
	"it":
	{
		"label_ApplyWatermarkOnPreview": "Watermark su anteprima",
		"label_OpenAccount": "Open Account",
		"editSgpClientSettings": "Modifica Impostazioni",
		"formSgpClientSettings":
		{
			"ApplyWatermarkOnPreview": "Watermark su anteprima",
			"OpenAccount": "Open Account",
			"DefaultLicenseId": "Licenza d'uso predefinita"
		}
	}
}
</i18n>
