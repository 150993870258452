<template>
	<v-dialog
		:value="value"
		max-width="500px"
		@keydown.esc="$emit('input', false)"
		persistent
		no-click-animation
	>
		<v-toolbar class="primary white--text">
			<span v-if="mode === 1">{{ $t("NewShippingAddress") }}</span>
			<span v-else>{{ $t("UpdateShippingAddress") }}</span><v-spacer></v-spacer>
					<v-btn icon dark @click="$emit('input', false)">
						<v-icon>close</v-icon>
					</v-btn>
		</v-toolbar>
	<v-card>

	<v-card-text>
		<v-form	v-if="metadataLoaded">
			<v-overlay :absolute="true" :value="FormLoading" :z-index="4">
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</v-overlay>
			<v-container fluid>
				<v-row dense>
					<v-col>
						<v-text-field v-model="formShippingAddress.AddressTitle"
							:label="$t('formShippingAddress.AddressTitle')"
							:counter="metadata['AddressTitle'].MaxLength"
							v-validate="metadata['AddressTitle'].ClientValidators"
							data-vv-scope="formShippingAddress"
							:data-vv-as="$t('formShippingAddress.AddressTitle')"
							data-vv-name="AddressTitle"
							key="formShippingAddress.AddressTitle"
							:error-messages="errors.collect('formShippingAddress.AddressTitle')"></v-text-field>
					</v-col>
				</v-row>

				<v-row dense>
					<v-col>
						<v-text-field v-model="formShippingAddress.DeliveryAddress"
							:label="$t('formShippingAddress.DeliveryAddress')"
							:counter="metadata['DeliveryAddress'].MaxLength"
							v-validate="metadata['DeliveryAddress'].ClientValidators"
							data-vv-scope="formShippingAddress"
							:data-vv-as="$t('formShippingAddress.DeliveryAddress')"
							data-vv-name="DeliveryAddress"
							key="formShippingAddress.DeliveryAddress"
							:error-messages="errors.collect('formShippingAddress.DeliveryAddress')"></v-text-field>
					</v-col>
				</v-row>

				<v-row dense>
					<v-col>
						<v-text-field v-model="formShippingAddress.AdditionalInfo"
							:label="$t('formShippingAddress.AdditionalInfo')"
							:counter="metadata['AdditionalInfo'].MaxLength"
							v-validate="metadata['AdditionalInfo'].ClientValidators"
							data-vv-scope="formShippingAddress"
							:data-vv-as="$t('formShippingAddress.AdditionalInfo')"
							data-vv-name="AdditionalInfo"
							key="formShippingAddress.AdditionalInfo"
							:hint="$t('formShippingAddress.AdditionalInfo_hint')"
							:error-messages="errors.collect('formShippingAddress.AdditionalInfo')"></v-text-field>
					</v-col>
				</v-row>
				<!-- "DeliveryAddress": "Indirizzo di consegna",
				"AdditionalInfo": "Ulteriori informazioni",
				"AdditionalInfo.hint": "Codice, edificio, azienda",
				"AddressTitle": "Nome e cognole", -->

				<v-row dense>
					<v-col>
						<v-text-field v-model="formShippingAddress.PostalCode"
							:label="$t('formShippingAddress.PostalCode')"
							:counter="metadata['PostalCode'].MaxLength"
							v-validate="metadata['PostalCode'].ClientValidators"
							data-vv-scope="formShippingAddress"
							:data-vv-as="$t('formShippingAddress.PostalCode')"
							data-vv-name="PostalCode"
							key="formShippingAddress.PostalCode"
							:error-messages="errors.collect('formShippingAddress.PostalCode')"></v-text-field>
					</v-col>
				</v-row>

				<v-row dense>
						<v-col>
							<v-text-field v-model="formShippingAddress.City"
										:label="$t('formShippingAddress.City')"
										:counter="metadata['City'].MaxLength"
										v-validate="metadata['City'].ClientValidators"
										data-vv-scope="formShippingAddress"
										:data-vv-as="$t('formShippingAddress.City')"
										data-vv-name="City"
										key="formShippingAddress.City"
										:error-messages="errors.collect('formShippingAddress.City')"></v-text-field>
						</v-col>
					</v-row>

					<v-row dense>
						<v-col>
							<CountryPicker v-model="formShippingAddress.CountryId"
									:label="$t('formShippingAddress.CountryId')"
									:counter="metadata['CountryId'].MaxLength"
									v-validate="metadata['CountryId'].ClientValidators"
									data-vv-scope="formShippingAddress"
									:data-vv-as="$t('formShippingAddress.CountryId')"
									data-vv-name="CountryId"
									key="formShippingAddress.CountryId"
									:error-messages="errors.collect('formShippingAddress.CountryId')">
							</CountryPicker>
						</v-col>
					</v-row>
			</v-container>
		</v-form>

	</v-card-text>
<v-card-actions>
	<v-spacer></v-spacer>
	<v-btn @click="$emit('input', false)" text primary>{{ $t("common.cancel") }}</v-btn>
	<v-btn v-if="mode === 1" color="primary" @click="AddOrSaveShippingAddress">{{ $t("common.add") }}</v-btn>
	<v-btn v-if="mode === 2" color="primary" @click="AddOrSaveShippingAddress">{{ $t("common.update") }}</v-btn>
</v-card-actions>
</v-card>
	</v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import metadata from "@/mixins/metadata";
// C: \Users\garod\OneDrive\Documenti\Visual Studio 2017\Projects\GenericCMS\sgp - qcommerce\src\components\Shared\FormControl\CountryPicker.vue
import CountryPicker from "@/components/Shared/FormControl/CountryPicker";

export default {
	name: "ShippingAddressesForm",
	$_veeValidate: { validator: "ShippingAddressesForm" },
	components: { CountryPicker },
	mixins: [metadata],
	props: {
		value: { type: Boolean, required: true },
		mode: { type: Number, required: true },
		ShippingAddressId: { type: Number },
		UserId: { type: String },
	},
	data() {
		return {
			FormLoading: false,
			formShippingAddress: {
				AddressTitle: null,
				AdditionalInfo: null,
				DeliveryAddress: null,
				PostalCode: null,
				City: null,
				CountryId: null
			}
		};
	},
	watch: {
		value: async function (val) {
			if (!val) this.ClearForm();
		},
		ShippingAddressId: {
			immediate: true,
			handler: async function (val) {
				this.$log.debug("watch ShippingAddressId");
				this.$log.debug(val);

				if (val) {
					this.itemDetails = await this.LoadDetails(val);
				}
			}
		},
	},
	created() {
		if (this.UserId) {
			this.ShippingAddressesService = new CrudClient("Shop/ShippingAddresses");
		} else {
			this.ShippingAddressesService = new CrudClient(
				"Shop/ShopClients/ShippingAddresses"
			);
		}
	},
	async mounted() {
		await this.LoadMetadata(this.ShippingAddressesService);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async AddOrSaveShippingAddress() {
			const validationResult = await this.$validator.validateAll(
				"formShippingAddress"
			);
			this.$log.debug("validation result:" + validationResult);
			if (!validationResult) {
				this.$log.info("form [formShippingAddress] not valid");
				return false;
			}
			try {
				this.FormLoading = true;
				const dto = {};

				dto.CountryId = this.formShippingAddress.CountryId;
				dto.City = this.formShippingAddress.City;
				dto.PostalCode = this.formShippingAddress.PostalCode;
				dto.DeliveryAddress = this.formShippingAddress.DeliveryAddress;
				dto.AdditionalInfo = this.formShippingAddress.AdditionalInfo;
				dto.AddressTitle = this.formShippingAddress.AddressTitle;

				if (this.mode === 1) {
					const urlPostFix = this.UserId ? `User/${this.UserId}` : null;
					await this.ShippingAddressesService.PostCustom(null, dto, urlPostFix, false);
				} else {
					await this.ShippingAddressesService.Patch(this.ShippingAddressId, dto, false);
				}
				this.snackSuccess({ Text: this.$t("common.success.updateData") });
				this.$emit("updated", this.ShippingAddressId);
				this.ClearForm();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("brand.error.cannotSaveData") });
			} finally {
				this.FormLoading = false;
			}
		},

		async LoadDetails(id) {
			try {
				this.FormLoading = true;

				const shippingAddressDetails = await this.ShippingAddressesService.GetCustom(id);

				this.formShippingAddress.City = shippingAddressDetails.City;
				this.formShippingAddress.DeliveryAddress = shippingAddressDetails.DeliveryAddress;
				this.formShippingAddress.AdditionalInfo = shippingAddressDetails.AdditionalInfo;
				this.formShippingAddress.AddressTitle = shippingAddressDetails.AddressTitle;
				this.formShippingAddress.PostalCode = shippingAddressDetails.PostalCode;
				this.formShippingAddress.CountryId = shippingAddressDetails.CountryId;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("brand.error.cannotLoadDetails") });
			} finally {
				this.FormLoading = false;
			}
		},

		ClearForm() {
			this.formShippingAddress.City = null;
			this.formShippingAddress.DeliveryAddress = null;
			this.formShippingAddress.AdditionalInfo = null;
			this.formShippingAddress.AddressTitle = null;
			this.formShippingAddress.PostalCode = null;
			this.formShippingAddress.CountryId = null;

			this.$validator.reset();
		},
	},
};
</script>
<i18n>
{
	"it": {
		"NewShippingAddress": "Nuovo indirizzo di consegna",
		"UpdateShippingAddress": "Aggiorna il tuo indirizzo di consegna",
		"formShippingAddress":{
			"DeliveryAddress": "Indirizzo di consegna",
			"AdditionalInfo": "Ulteriori informazioni",
			"AdditionalInfo_hint": "Codice, edificio, azienda",
			"AddressTitle": "Nome e cognome",
			"City": "Città",
			"Address": "Indirizzo",
			"PostalCode": "CAP",
			"CountryId": "Paese"
		}
	},
	"en": {
		"NewShippingAddress": "Add billing information",
		"UpdateShippingAddress": "Update billing information",
		"formShippingAddress":{
			"DeliveryAddress": "Delivery address",
			"AdditionalInfo": "Additional address information",
			"AdditionalInfo_hint": "Code, building, company",
			"AddressTitle": "Full name",
			"City": "City",
			"Address": "Registred address",
			"PostalCode": "Postal Code",
			"CountryId": "Country"
		}
	}
}
</i18n>
