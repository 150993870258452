<template>
	<v-autocomplete
		:label="$attrs.label || $t('common.name')"
		:items="Users"
		:loading="LoadingUsers"
		item-text="AspNetUser.UserProfile.DisplayName"
		item-value="UserId"
		v-bind="$attrs"
		v-bind:value="value"
		v-on="$listeners"
		@input="$emit('input', value)"
		:search-input.sync="search"
		clearable
	></v-autocomplete>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

export default {
	data() {
		return {
			LoadingUsers: false,
			Users: [],
			search: null,
		};
	},
	props: {
		value: { type: Number, default: null },
		// addEmptyItem: { type: Boolean, required: false, default: false },
	},
	created() {
		this.ApplicationUsersService = new CrudClient("UserManagement/Users");
	},
	watch: {
		async search(val) {
			this.$log.debug("Watch search: " + val);
			val && val !== this.value && val.length > 0 && (await this.LoadUsers(val));
		},

		value: {
			immediate: true,
			handler: async function (newVal) {
				if (!newVal) return;
				// Programmatic value set
				if (!this.Users.find((i) => i.EventId === newVal)) {
					const res = await this.ApplicationUsersService.GetSelectedFields(
						newVal,
						"AspNetUser.UserProfile.DisplayName, UserId"
					);

					this.search = res.Name;
				}
			},
		},
	},
	async mounted() {
		// await this.LoadUsers();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		async LoadUsers(val) {
			try {
				this.LoadingUsers = true;

				const res = await this.ApplicationUsersService.GetPaged({
					skip: 0,
					limit: 10,
					filter: `AspNetUser.UserProfile.DisplayName:like:${val}`,
					fields: "UserId, AspNetUser.UserProfile.DisplayName",
					orderBy: "UserId:desc",
				});

				this.Users = res.Data;
				// if (this.addEmptyItem) this.Users.unshift({ EventId: null, Name: "-" });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingUsers = false;
			}
		},
	},
};
</script>
