<template>
	<div>
		<vue-headful
			v-if="this.UserDetails.AspnetUser"
			:title="this.UserDetails.AspnetUser.UserName"
			description=""
		/>
		<v-breadcrumbs :items="breadcrumbsItems" class="pl-3">
      <template v-slot:divider>
			<v-icon class="secondary--text text--lighten-2"
				>fas fa-caret-right</v-icon
			>
      </template>
		</v-breadcrumbs>

		<v-progress-linear
			:indeterminate="true"
			v-show="LoadingData"
		></v-progress-linear>

		<h1 class="text-h4 text-sm-h2" v-if="this.UserDetails.AspnetUser">
			{{ this.UserDetails.AspnetUser.UserName }}
		</h1>
		<div class="text-center mb-2"  v-if="OwnerDetails">
			<router-link :to="'/Users/EditUser/' + OwnerDetails.Id">
				Sub account di {{ OwnerDetails.DisplayName }}
			</router-link>
		</div>
		<v-expansion-panels multiple>
			<v-expansion-panel>
				<v-expansion-panel-header
					><span class="headline ma-0 pa-0">{{ $t("UserProfile") }}</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<UserProfile
						:UserId="this.$route.params.UserId"
						:Roles="UserRoles"
					></UserProfile>

					<DeleteUserCard
						:UserId="this.$route.params.UserId"
						:IsClientSubAccount="IsClientSubAccount"
					>
					</DeleteUserCard>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel v-if="IsClient || IsShopClient">
				<v-expansion-panel-header
					><span class="headline ma-0 pa-0"> Dati di Fatturazione</span>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<UserBillingProfiles
						:UserId="this.$route.params.UserId"
					></UserBillingProfiles>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel v-if="IsClient">
				<v-expansion-panel-header>
					<span class="headline ma-0 pa-0">{{
						$t("header_ClientDefaultPrices")
					}}</span></v-expansion-panel-header
				>
				<v-expansion-panel-content>
					<SgpClientClientDefaultPrices :UserId="this.$route.params.UserId">
					</SgpClientClientDefaultPrices>
				</v-expansion-panel-content>
			</v-expansion-panel>
				<v-expansion-panel v-if="IsShopClient">
					<v-expansion-panel-header>
						<span class="headline ma-0 pa-0">{{
							$t("header_ShippingAddresses")
						}}</span></v-expansion-panel-header
					>
					<v-expansion-panel-content>
						<UserShippingAddresses :UserId="this.$route.params.UserId">
						</UserShippingAddresses>
					</v-expansion-panel-content>
				</v-expansion-panel>
			<v-expansion-panel>
				<v-expansion-panel-header>
					<span class="headline ma-0 pa-0">{{
						$t("header_UserRoles")
					}}</span></v-expansion-panel-header
				>
				<v-expansion-panel-content>
					<v-card-text>
						<v-checkbox
							v-for="role in Roles"
							:key="role.Id"
							:label="role.Name"
							v-model="role.Selected"
							color="red"
							hide-details
						></v-checkbox>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							text
							color="primary"
							@click="LoadUserRoles"
							:loading="LoadingData"
							>{{ $t("common.cancel") }}</v-btn
						>
						<v-btn color="primary" @click="UpdateRoles" :loading="LoadingData">{{
							$t("common.save")
						}}</v-btn>
					</v-card-actions>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel v-if="IsClient || IsClientSubAccount">
				<v-expansion-panel-header>
					<span class="headline ma-0 pa-0">{{
						$t("header_userTags")
					}}</span></v-expansion-panel-header
				>
				<v-expansion-panel-content>
					<UserTags :UserId="this.$route.params.UserId"></UserTags>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel v-if="IsClient">
				<v-expansion-panel-header>
					<span class="headline ma-0 pa-0">{{
						$t("header_userSubAccounts")
					}}</span></v-expansion-panel-header
				>
				<v-expansion-panel-content>
					<ClientSubAccounts :UserId="this.$route.params.UserId"></ClientSubAccounts>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import UserBillingProfiles from "@/components/Account/UserBillingProfiles.vue";
import UserShippingAddresses from "@/components/Account/UserShippingAddresses.vue";
import UserProfile from "@/components/Account/UserProfile.vue";
import SgpClientClientDefaultPrices from "@/components/Account/SgpClientDefaultPrices.vue";

import UserTags from "@/components/Account/UserTags.vue";
import ClientSubAccounts from "@/components/Account/ClientSubAccounts.vue";
import DeleteUserCard from "./DeleteUserCard.vue";

export default {
	name: "EditUser",
	components: {
		UserBillingProfiles,
		UserShippingAddresses,
		UserProfile,
		SgpClientClientDefaultPrices,
		DeleteUserCard,
		UserTags,
		ClientSubAccounts,
	},
	data() {
		return {
			LoadingData: false,
			Roles: [],
			UserRoles: [],
			UserDetails: [],
			OwnerDetails: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
		};
	},
	computed: {
		IsClient() {
			return this.IsCurrentUserInRole("Client");
		},

		IsShopClient() {
			return this.IsCurrentUserInRole("ShopClient");
		},

		IsClientSubAccount() {
			return this.IsCurrentUserInRole("ClientSubAccount");
		},
	},

	created() {
		// add services to this
		this.RolesService = new CrudClient("UserManagement/Roles");
		this.UserRolesService = new CrudClient("UserManagement/UserRoles");
		this.UserProfilesService = new CrudClient("UserManagement/UserProfile");
		this.UserManagementExtensionsService = new CrudClient("UserManagementExtensions/Users");
	},
	async mounted() {
		this.$log.info("EditUser Mounted");
		this.breadcrumbsItems.push(
			{
				text: this.$t("users"),
				disabled: false,
				exact: true,
				to: "/Users",
			},
			{
				text: this.$t("pageTitle"),
				disabled: false,
				exact: true,
				to: "/Users/UserList",
			}
		);
		await this.LoadAllRoles();
		await this.LoadUserRoles();
		await this.LoadUserDetails(this.$route.params.UserId);

		if (this.IsClientSubAccount) {
			const ownerUserId = await this.UserManagementExtensionsService.Get("OwnerUserId/" + this.$route.params.UserId);
			this.$log.debug("Owner:" + ownerUserId);
			this.OwnerDetails = await this.UserProfilesService.GetCustom(ownerUserId, false);
		}
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		IsCurrentUserInRole(roleName) {
			return !!this.UserRoles.find((ur) => ur.RoleName === roleName);
		},

		async LoadAllRoles() {
			try {
				this.LoadingData = true;
				const res = await this.RolesService.GetCustom(null, false);
				res.forEach((element) => {
					element.Selected = false;
				});
				this.Roles = res;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		async LoadUserRoles() {
			try {
				this.LoadingData = true;
				this.Roles.forEach((element) => {
					element.Selected = false;
				});
				const res = await this.UserRolesService.Get(this.$route.params.UserId);
				this.UserRoles = res;
				res.forEach((element) => {
					this.Roles.find((r) => r.Id === element.RoleId).Selected = true;
				});
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadUserRoles") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		async UpdateRoles() {
			try {
				this.LoadingData = true;
				const selectedRoles = this.Roles.filter((r) => r.Selected).map((r) => r.Id);
				await this.UserRolesService.Post(
					this.$route.params.UserId,
					selectedRoles,
					"SetRoles"
				);
				this.LoadUserRoles();
				this.snackSuccess({ Text: this.$t("snackSuccess_UserRolesUpdated") });
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotUpdateUserRoles") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		async LoadUserDetails(id) {
			try {
				this.LoadingData = true;
				this.UserDetails = await this.UserProfilesService.GetCustom(id, false);
				const c = {
					text: this.UserDetails.AspnetUser.UserName,
					disabled: true,
					exact: true,
					to: "/Users/EditUser/{id}",
				};
				this.breadcrumbsItems.push(c);
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadDetails") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},
	},
};
</script>

<i18n>
{
    "it": {
		"pageTitle": "Elenco Utenti",
		"users": "Gestione utenti",
		"pageMetaDescription": "Gestisci gli utenti",
		"snackError_CannotLoadUserRoles": "Cannot load user roles",
		"snackError_CannotUpdateUserRoles": "Cannot update user roles",
		"header_UserRoles": "Ruoli dell'Utente",
		"header_ClientDefaultPrices": "Listino predefinito",
		"header_userTags": "Tag dell'Utente",
		"snackSuccess_UserRolesUpdated":"Ruoli sono stati aggiornati!",
		"UserName": "Nome Utente",
		"UserProfile": "Profilo Utente",
		"Email": "E-mail",
		"header_userSubAccounts": "Sub Account",
		"header_ShippingAddresses": "Indirizzi di consegna"
    }
}
</i18n>
