<template>
	<div>
		<confirm ref="confirm"></confirm>
		<!-- <v-card class="mb-4">
			<v-card-title> Listino Cliente</v-card-title> -->
		<v-alert type="info" v-if="clientDefaultPrices.length === 0">
			Per questo cliente e' valido il listino standard. <br />
			Puoi selezionare un listino personalizzato.
		</v-alert>

		<v-card-text>
			<v-list>
				<v-list-item v-for="l in clientDefaultPrices" :key="l.LicenseId">
					<v-list-item-content>
						<v-list-item-title> {{ l.PhotographyLicense.Name }}</v-list-item-title>
						<v-list-item-subtitle>
							{{ l.Price | formatCurrencyAvoidDecimal }}</v-list-item-subtitle
						>
					</v-list-item-content>
					<v-list-item-action>
						<v-btn icon @click="deleteClientDefaultPrice(l.LicenseId)"
							><v-icon>fas fa-trash</v-icon></v-btn
						>
					</v-list-item-action>
				</v-list-item>
			</v-list>
			<v-row dense>
				<v-col cols="6" sm="4">
					<v-select
						:label="$t('sgp.license')"
						v-model="selectedLicenseId"
						:items="selectableLicenses"
						item-text="Name"
						item-value="LicenseId"
					></v-select>
				</v-col>
				<v-col cols="6" sm="4">
					<v-text-field
						:label="$t('common.price')"
						type="number"
						v-model="inputPrice"
					>
					</v-text-field
				></v-col>
				<v-col cols="12" sm="4">
					<v-btn
						block
						color="primary"
						:loading="LoadingData"
						:disabled="!(inputPrice > 0 && selectedLicenseId != null)"
						@click="AddDefaultPrice"
						>{{ $t("common.add") }}</v-btn
					></v-col
				>
			</v-row>
		</v-card-text>
		<!-- </v-card> -->
	</div>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import Confirm from "@/components/Shared/Common/Confirm";
import metadata from "@/mixins/metadata";

export default {
	$_veeValidate: { validator: "EditUserProfile" },
	components: { Confirm },
	mixins: [metadata],
	props: {
		UserId: {
			type: String,
		},
	},
	data() {
		return {
			clientDefaultPrices: [],
			photographyLicenses: [],
			LoadingData: false,
			inputPrice: [],
			selectedLicenseId: null,
		};
	},
	computed: {
		selectableLicenses() {
			return this.photographyLicenses.filter((l) => {
				return !this.clientDefaultPrices.find(
					(cdp) => cdp.LicenseId === l.LicenseId
				);
			});
		},
	},
	created() {
		this.PhotographyLicensesService = new CrudClient("SGP/PhotographyLicenses");

		this.SgpClientDefaultPricesService = new CrudClient(
			"SGP/ClientDefaultPrices"
		);
	},
	async mounted() {
		await this.LoadMetadata(this.SgpClientDefaultPricesService);
		await this.LoadLicenses();
		await this.LoadClientDefaultPrices();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadClientDefaultPrices() {
			try {
				this.LoadingData = true;

				// {applicationId}/User/{userId}
				const res = await this.SgpClientDefaultPricesService.GetPaged({
					skip: 0,
					limit: 0,
					fields: "*, PhotographyLicense.*",
					urlPostFix: "User/" + this.UserId,
				});

				this.clientDefaultPrices = res.Data;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
			}
		},

		//   [Route("{applicationId}/{userId}/{licenseId}")]
		async deleteClientDefaultPrice(licensId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete")
				))
			) {
				return;
			}

			try {
				this.LoadingData = true;

				await this.SgpClientDefaultPricesService.Delete(
					null,
					this.UserId + "/" + licensId
				);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
				this.LoadClientDefaultPrices();
			}
		},

		async AddDefaultPrice() {
			const dto = { Price: this.inputPrice };
			await this.SgpClientDefaultPricesService.PostCustom(
				this.UserId,
				dto,
				this.selectedLicenseId
			);

			this.LoadClientDefaultPrices();
		},

		async LoadLicenses() {
			try {
				this.LoadingData = true;

				const res = await this.PhotographyLicensesService.GetPaged({
					skip: 0,
					limit: 0,
					fields: "*, PhotographyLicenseArea.*, MediaType.*",
				});

				this.photographyLicenses = res.Data;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
			}
		},
	},
};
</script>
<i18n>
{
	"it":
	{
		"label_ApplyWatermarkOnPreview": "Watermark su anteprima",
		"label_OpenAccount": "Open Account",
		"editSgpClientSettings": "Modifica Impostazioni",
		"formSgpClientSettings":
		{
			"ApplyWatermarkOnPreview": "Watermark su anteprima",
			"OpenAccount": "Open Account"
		}
	}
}
</i18n>
